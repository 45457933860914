
export default {
    path: "/contracts-subscriptions",
    name: "ContractsSubscriptions",
    meta: {
        authRequired: true,
    },
    component: () => import("./contracts-subscriptions.vue")
}

