
export default {
    path: "/contracts",
    name: "Contracts",
    meta: {
        authRequired: true,
    },
    component: () => import("./contracts.vue")
}

