
export default {
    path: "/schools-contracts",
    name: "SchoolsContracts",
    meta: {
        authRequired: true,
    },
    component: () => import("./schools-contracts.vue")
}

