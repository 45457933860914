
export default {
    path: "/budgets-books",
    name: "BudgetsBooks",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-books.vue")
}

