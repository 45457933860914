
export default {
    path: "/contracts-fields",
    name: "ContractsFields",
    meta: {
        authRequired: true,
    },
    component: () => import("./contracts-fields.vue")
}

