
export default {
    path: "/budgets-invoices-slips",
    name: "BudgetsInvoicesSlips",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-invoices-slips.vue")
}

