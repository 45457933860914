
export default {
    path: "/contracts-attachments",
    name: "ContractsAttachments",
    meta: {
        authRequired: true,
    },
    component: () => import("./contracts-attachments.vue")
}

