
export default {
    path: "/peoples",
    name: "Peoples",
    meta: {
        authRequired: true,
    },
    component: () => import("./peoples.vue")
}

