
export default {
    path: "/budgets-comments",
    name: "BudgetsComments",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-comments.vue")
}

