
export default {
    path: "/budgets-address",
    name: "BudgetsAddress",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-address.vue")
}

