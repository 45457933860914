
export default {
    path: "/peoples-types",
    name: "PeoplesTypes",
    meta: {
        authRequired: true,
    },
    component: () => import("./peoples-types.vue")
}

