
export default {
    path: "/budgets-steps-next",
    name: "BudgetsStepsNext",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-steps-next.vue")
}

