
export default {
    path: "/budgets-invoices",
    name: "BudgetsInvoices",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-invoices.vue")
}

