
export default {
    path: "/schools-contracts-fields",
    name: "SchoolsContractsFields",
    meta: {
        authRequired: true,
    },
    component: () => import("./schools-contracts-fields.vue")
}

