
export default {
    path: "/schools-infos",
    name: "SchoolsInfos",
    meta: {
        authRequired: true,
    },
    component: () => import("./schools-infos.vue")
}

