
export default {
    path: "/budgets",
    name: "Budgets",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets.vue")
}

