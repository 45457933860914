
export default {
    path: "/contracts-comments",
    name: "ContractsComments",
    meta: {
        authRequired: true,
    },
    component: () => import("./contracts-comments.vue")
}

