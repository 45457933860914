
export default {
    path: "/peoples-races",
    name: "PeoplesRaces",
    meta: {
        authRequired: true,
    },
    component: () => import("./peoples-races.vue")
}

