
export default {
    path: "/books-categories",
    name: "BooksCategories",
    meta: {
        authRequired: true,
    },
    component: () => import("./books-categories.vue")
}

