
export default {
    path: "/books",
    name: "Books",
    meta: {
        authRequired: true,
    },
    component: () => import("./books.vue")
}

