
export default {
    path: "/budgets-devolutions",
    name: "BudgetsDevolutions",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-devolutions.vue")
}

