
export default {
    path: "/budgets-steps",
    name: "BudgetsSteps",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-steps.vue")
}

