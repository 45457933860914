export const state = {
  teacher: {},
  students: [],
  classRoom: {},
};

export const getters = {
  getNewClass(state) {
    const newClass = {
      teacher: { ...state.teacher },
      students: [...state.students],
      classRoom: { ...state.classRoom },
    };

    return newClass;
  },
};

export const mutations = {
  SET_TEACHER(state, teacher) {
    state.teacher = teacher;
  },
  SET_STUDENTS(state, students) {
    state.students = students;
  },
  SET_CLASSROOM(state, classRoom) {
    state.classRoom = classRoom;
  },
};

export const actions = {
  saveTeacher({ commit }, payload) {
    commit("SET_TEACHER", payload);
  },
  saveStudents({ commit }, payload) {
    commit("SET_STUDENTS", payload);
  },
  saveClassRoom({ commit }, payload) {
    commit("SET_CLASSROOM", payload);
  },
  saveNewClass({ commit }, payload) {
    console.log(payload);
    commit("");
  },
};
