
export default {
    path: "/books-pages",
    name: "BooksPages",
    meta: {
        authRequired: true,
    },
    component: () => import("./books-pages.vue")
}

