
export default {
    path: "/users",
    name: "Users",
    meta: {
        authRequired: true,
    },
    component: () => import("./users.vue")
}

