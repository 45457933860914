
export default {
    path: "/budgets-status",
    name: "BudgetsStatus",
    meta: {
        authRequired: true,
    },
    component: () => import("./budgets-status.vue")
}

